import axiosClient from "./axiosClient";

const cartApi = {
    export: async () => {
        const url = "/ecom/v1/carts/export";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
    fileDownload: async () => {
        const url = "/ecom/v1/carts/downloads";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
};

export default cartApi;