import { BASE_URL_API } from "../../configs"
import { QRCodeSVG } from "qrcode.react"

type ProfileQrCodeProps = {
    url:string,
    urlImage?:string,
}

export const ProfileQrCode = ({...ProfileQrCode}: ProfileQrCodeProps) => {
    const urlImage = ProfileQrCode.urlImage ?? `${BASE_URL_API}/images/brands/dafcLogo.jpg`
    return (<>
        <QRCodeSVG
            id="qr-profile"
            size={450}
            value={ProfileQrCode.url}
            level={"H"}
            imageSettings={{
                src: urlImage,
                x: undefined,
                y: undefined,
                height: 72,
                width: 72,
                excavate: true,
            }}
        />
    </>)

} 

export const ProfileQrCodeSmall = ({...ProfileQrCode}: ProfileQrCodeProps) => {
    const urlImage = ProfileQrCode.urlImage ?? `${BASE_URL_API}/images/brands/dafcLogo.jpg`
    return (<>
        <QRCodeSVG
            id="qr-profile"
            size={150}
            value={ProfileQrCode.url}
            level={"H"}
            imageSettings={{
                src: urlImage,
                x: undefined,
                y: undefined,
                height: 24,
                width: 24,
                excavate: true,
            }}
        />
    </>)

} 