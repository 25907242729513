import { useParams } from "react-router-dom";
import BrandFooter from "../../components/profile/brandFooter"
import Profile from "../../components/profile/info"
import { useEffect, useState } from "react";
import { BASE_URL_API, DAFC_URL } from "../../configs";

const ProfilePublicPage = () => {

    const { username } = useParams()
    const [data, setData] = useState<any>()
    const getProfileApi = async (username: string|undefined) => {
        try {
            const response = await fetch(`${BASE_URL_API}/client/v1/users/share-profile/${username}`)
            const rep = await response.json();
            if (response.ok) {
                setData(rep)
            }else{
                window.location.replace(DAFC_URL);
            }
        } catch (error) {
            window.location.replace(DAFC_URL);
            console.log(error)
        }
    }

    useEffect(() => {
        getProfileApi(username)
    }, [])

    const profile = data?.data

    return data && (
        <main className="h-full text-white bg-[#292929]">
            <div className="overflow-hidden w-[500px] max-w-[100%] m-auto">
                <Profile {...profile} />
                <BrandFooter />
            </div>
        </main>
    )
}

export default ProfilePublicPage;