import { LoginPayload } from "../models";
import axiosClient from "./axiosClient";

const authApi = {
  loginApi: async (data: LoginPayload) => {
    const url = "/admin/auth/login";
    const response = await axiosClient.post(url, data);
    const User = response.data.data;
    return {
      accessToken: User.token,
      user: User,
    };
  },

  logout: async () => {},

  getProfile: async () => {
    const url = "/admin/users/me";
    const response = await axiosClient.get(url);
    const User = response.data.data;
    localStorage.setItem("currentUser", JSON.stringify(User));
    return User;
  },
};

export default authApi;
