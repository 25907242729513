import { useState, createContext, useContext, PropsWithChildren } from "react";
import SidebarDashboard from "./dashboardSidebar";
import { Box } from "@mui/material";
import { ProSidebarProvider } from "react-pro-sidebar";

const SidebarContext = createContext({});

export const SidebarProvider = ({ children }: PropsWithChildren) => {
  const [sidebarBackgroundColor, setSidebarBackgroundColor] =
    useState(undefined);
  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,
        }}
      >
        <Box
        display={"flex"}
        flexDirection={"row"}
        >
          <SidebarDashboard />
          {children}
        </Box>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);