import { ProfileQrCode } from "../../../components/qrcode/ProfileQrCode"
import { Navigate } from "react-router-dom"
import { Box } from "@mui/material"
import { useEffect } from "react"
import * as htmlToImage from 'html-to-image'

const QrDownload = () => {

    const queryParameters = new URLSearchParams(window.location.search)
    const filename = queryParameters.get("filename") ?? ''
    const url = window.location.origin + "/profile/"+ filename

    useEffect(()=>{
        (async()=>{
            await downloadQRCode(filename)
            setTimeout(()=>{window.close()},1000)
        })()
    })

    if(!filename) return <Navigate to="/"/>

    const downloadQRCode = async (filename: string) => {
        const qrHtmlEl = await document.getElementById("qr-profile")
        if (!qrHtmlEl) throw new Error("<qr> not found in DOM")
        const pngUrl = await htmlToImage.toPng(qrHtmlEl)
        const downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = `${filename}.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    return(
        <Box 
            display={"flex"} 
            justifyContent={"center"} 
            m="20px"
        >
            <Box
                p={1} 
                bgcolor={"white"}
                id = {'qr-profile'}
            >
                <ProfileQrCode url={url}/>
            </Box>
        </Box>
    )
}

export default QrDownload;