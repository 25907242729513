import { useState } from "react";
import { Menu, Sidebar, MenuItem as MItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme/theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { BASE_URL_API } from "../../../configs";
import { MenuItem } from "./MenuItem";
import { Leaderboard, ShoppingCart } from "@mui/icons-material";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';

const SidebarDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const user = JSON.parse(localStorage.getItem("currentUser") ?? "");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { collapseSidebar } = useProSidebar();

  user.urlAvatar = BASE_URL_API + (user.urlAvatar ?? '/images/brands/dafcLogo.jpg')

  return (
    <Box
      position={"sticky"}
      display={"flex"}
      height={"100vh"}
      top={"0"}
      bottom={"0"}
      zIndex={1000}
      sx={{
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
        },
        "& .sub-menu-content": {
          backgroundColor: colors.blueAccent[800],
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[300]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .sub-menu-content .menu-item > a": {
          marginLeft: "20px",
        },
      }}
    >
      <Sidebar
        defaultCollapsed={isCollapsed}
        breakPoint="md"
        backgroundColor={colors.primary[400]}
      >
        <Menu>
          {/* LOGO AND MENU ICON */}
          <MItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={
              isCollapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : undefined
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.primary[100]}>
                  DAFC
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MItem>

          {!isCollapsed && (
            <Link to={`/me`}>
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    src={user.urlAvatar}
                    className="cursor-pointer rounded-full object-cover h-100 w-100"
                    style={{ height: '100px', width: '100px' }}
                  />
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]}>
                    {user?.position}
                  </Typography>
                </Box>
              </Box>
            </Link>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <MenuItem to='/'
              title="Dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              label="Carts"
              icon={<ShoppingCartOutlinedIcon />}
            >
              <MenuItem
                title="Export"
                to="/carts/export"
                icon={<DownloadOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              label="Wishlists"
              icon={<FavoriteBorderIcon />}
            >
              <MenuItem
                title="Export"
                to="/wishlists/export"
                icon={<DownloadOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              label="Products"
              icon={<LaptopMacOutlinedIcon />}
            >
              <MenuItem
                title="Export"
                to="/products/export"
                icon={<DownloadOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              label="Users"
              icon={<PeopleOutlineIcon />}
            >
              <MenuItem
                title="List"
                to="/users/list"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
          </Box>
        </Menu>

      </Sidebar>
    </Box>
  );
};

export default SidebarDashboard;
