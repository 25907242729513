import React, { PropsWithChildren } from 'react'
import Topbar from '../components/global/Topbar'
import { Box } from '@mui/material'
import { ProSidebarProvider } from 'react-pro-sidebar'

function BlankLayout({children}: PropsWithChildren) {
  return (
    <ProSidebarProvider>
    <Box 
      height={"100%"}
      width={"100%"}
    >
      <main>
            <Topbar isAuth={false} />
            {children}
      </main>
    </Box>
    </ProSidebarProvider>
  )
}

export default BlankLayout