import { Box } from "@mui/material";
import Header from "../../components/global/header";

function DashboardPage() {
  return (
    <Box m="20px">
      <Header title="Dashboard" subtitle="view report all" />
    </Box>
  );
}

export default DashboardPage;
