import { tokens } from "../../../theme/theme";
import { MenuItem as MItem } from "react-pro-sidebar";
import { Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

type PropItem = {
    title: string;
    to: string;
    icon: any;
    selected: string;
    setSelected: (title: string) => void;
    children?: React.ReactNode;
    
  };
  
export const MenuItem = ({ title, to, icon, selected, setSelected, children }: PropItem) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Link to={to}>
        <MItem
          active={selected === title}
          style={{
            color: colors.primary[100],
          }}
          onClick={() => {
            setSelected(title)
          }}
          icon={icon}
        >{children ?? (<Typography>{title}</Typography>)}
        </MItem>
      </Link>
    );
  };