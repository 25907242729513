import axiosClient from "./axiosClient";

const wishlistApi = {
    export: async () => {
        const url = "/ecom/v1/wishlists/export";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
    fileDownload: async () => {
        const url = "/ecom/v1/wishlists/downloads";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
};

export default wishlistApi;