import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme/theme";
import Header from "../../../components/global/header";
import { useEffect, useState } from "react";
import userApi from "../../../services/userApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

type CellProp = {
  row: {
    id: any;
    username: any;
  };
};

const UserList = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Id" },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstName",
      headerName: "First Name",
    },
    {
      field: "lastName",
      headerName: "Last Name",
    },
    { field: "mobileNo", headerName: "Phone Number", width: 100 },
    { field: "position", headerName: "Title", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: ({ row: { id , username} }: CellProp) => {
        return (
          <Box
            display={"flex"}
            margin={0}
            p={"5px"}
            width={"100px"}
            borderRadius={"4px"}
            justifyContent={"space-around"}
          >
            <Link to={`/profile/${username}`} target="_blank" rel="noopener noreferrer">
              <Button>
                  <Typography color={colors.greenAccent[200]} >
                    <RemoveRedEyeIcon/>
                  </Typography>
              </Button>
            </Link>
            <Link to={`/users/${id}/edit`}>
              <Button>
                  <Typography color={colors.greenAccent[500]} >
                    <EditIcon/>
                  </Typography>
                  </Button>
            </Link>
            <Link to={`/users/download/qr?filename=${username}`} target="_blank" rel="noopener noreferrer">
              <Button>
                  <Typography color={colors.blueAccent[500]} >
                    <FileDownloadIcon/>
                  </Typography>
                  </Button>
            </Link>
            <Button
              onClick={
                ()=>{
                  handleDeleteUserById(id,paginationModel.pageSize,paginationModel.page);
                  }
              }
            >
              <Typography color={colors.redAccent[500]}>
                  <DeleteIcon/>
              </Typography>
            </Button>
          </Box>
        );
      },
    },
  ];

  const handleDeleteUserById = async (id: string,paginate:number, page:number) => {

    const confiromBtn = window.confirm("Are you sure you want to delete?")
    if (!confiromBtn) return;

    if (await userApi.deleteUserById(id)) {
      toast.success(`Delete id ${id} success`);
      await getUsers(paginate,page);
    } else {
      toast.warning(`Delete ${id} faild`);
    }
  };

  const [itemCount, setItemCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const getUsers = async (paginate:number, page:number) => {
    setIsLoading(true)
    const rep = await userApi.getUsers(paginate,page);
    setUsers(rep.data);
    setItemCount(rep.paginator.itemCount)
    setIsLoading(false)
  };
  useEffect(() => {
    getUsers(paginationModel.pageSize, paginationModel.page);
  }, [paginationModel]);

  if (!users) return <>Not Found</>;

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CONTACT" subtitle="" />
      </Box>
      <Link to="/users/add">
        <Button variant="contained" color="secondary">
          <Typography color={colors.primary[100]}>
            <PersonAddAltOutlinedIcon className="pr-2"/>ADD CONTACT
          </Typography>
        </Button>
      </Link>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
          rows={users} 
          columns={columns}
          loading={isLoading}
          paginationModel={paginationModel} 
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          rowCount={itemCount}
          />
      </Box>
    </Box>
  );
};

export default UserList;
