import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { tokens } from "../../../theme/theme";
import Header from "../../../components/global/header";
import { useEffect, useState } from "react";
import wishlistApi from "../../../services/wishlistApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import { FileDownload } from "@mui/icons-material";

type CellProp = {
    row: {
        fileUrl : any,
        fileName: string,
    };
};

const WishlistExport = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        {
            field: "id",
            headerName: "Id",
            width: 200,
            cellClassName: "name-column--cell",
        },
        {
            field: "fileName",
            headerName: "File",
            width: 400,
            cellClassName: "name-column--cell",
        },
        {
            field: "action",
            headerName: "Action",
            width: 250,
            renderCell: ( {row: { fileUrl,fileName }}: CellProp ) => {
                return (
                    <Box
                        display={"flex"}
                        margin={0}
                        p={"5px"}
                        width={"100px"}
                        borderRadius={"4px"}
                        justifyContent={"space-around"}
                    >
                        <Link  to={`${fileUrl}`} download={fileName} >
                        <Button
                        >
                            <Typography color={colors.greenAccent[400]}>
                                <DownloadIcon />
                            </Typography>
                        </Button>
                        </Link>
                    </Box>
                );
            },
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [fileds, setFiles] = useState([]);
    const [currentFiled, setCurrentFile] = useState('');
    const onClickExportButton = async () => {
        setIsLoading(true)
        const res = await wishlistApi.export();
        if (res && res.status === 'SUCCESS') {
            const fileName = res.data?.filename ?? ''
            toast.success(`Export success file name: ${fileName}`)
            setCurrentFile( fileName)
            const response = await wishlistApi.fileDownload();
            if (response && response.status === 'SUCCESS') {
                setFiles(response.data)
            }
        }
        setIsLoading(false)
    };

    const getFileDownload = async () =>{
        const res = await wishlistApi.fileDownload()
        if (res && res.status === 'SUCCESS') {
            setFiles(res.data)
        }
    }
    useEffect(() => {
        getFileDownload()
    },[]);

    if (!fileds) return <>Not Found</>;

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="EXPORT WISHLIST" subtitle="" />
            </Box>
            <Button onClick={onClickExportButton} variant="contained" color="secondary">
                <Typography color={colors.primary[100]}>
                    <FileDownload className="pr-2" />EXPORT
                </Typography>
            </Button>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-row.first": {
                        backgroundColor: `${colors.redAccent[700]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={fileds}
                    columns={columns}
                    loading={isLoading}
                    getRowClassName={(params)=>{
                        console.log(params)
                        if(params.id === 1 && params.row.fileName === currentFiled ) return 'first'
                        return '';
                    }}
                    pagination
                    autoPageSize
                />
            </Box>
        </Box>
    );
};

export default WishlistExport;
