import axiosClient from "./axiosClient";

const userApi = {
  getUsers: async (paginate: number, page: number) => {
    page = page + 1
    const url = "/admin/users";
    const response = await axiosClient.post(url, {options:{paginate,page}});
    const Users = response.data.data;
    return Users;
  },

  getUserById: async (id: number | string | undefined) => {
    const url = `/admin/users/${id}`;
    const response = await axiosClient.get(url);
    const User = response.data.data;
    return User;
  },

  postCreateUser: async (payload: any) => {
    try {
      const url = `/admin/users/create`;
      const response = await axiosClient.post(url, payload);
      if (response?.data?.status === "SUCCESS") return true;
      return false;
    } catch (error) {
      return false;
    }
  },

  deleteUserById: async (id: string | number) => {
    const url = `/admin/users/delete/${id}`;
    const response = await axiosClient.delete(url);
    if (response.data?.status === "SUCCESS") return true;
    return false;
  },

  putUserPartial: async (id: string | number, payload: any) => {
    const url = `/admin/users/partial-update/${id}`;
    const response = await axiosClient.put(url, payload);
    if (response.data?.status === "SUCCESS") return true;
    return false;
  },

  putUpdateProfile: async(payload: any)=>{
    const url = `/admin/users/update-profile`;
    const response = await axiosClient.put(url, payload);
    if (response.data?.status === "SUCCESS") return true;
    return false;
  }
};

export default userApi;
