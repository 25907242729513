import { useEffect, useState } from "react";
import { Box, Button, TextField, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import Header from "../../../components/global/header";
import userApi from "../../../services/userApi";
import { useParams } from "react-router-dom";
import { convertToBase64 } from "../../../utils";
import { BASE_URL_API } from "../../../configs";
import { genderData } from "../../../components/common";
import { toast } from "react-toastify";
import { ProfileQrCode, ProfileQrCodeSmall } from "../../../components/qrcode/ProfileQrCode";

const UserEdit = () => {
  let [initialValues, setInitialValues] = useState({
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    position: "",
    urlAvatar: "",
    nickname: "",
    gender: "",
    urlPreview: "",
  });

  const [user, setUser] = useState();
  let { id } = useParams();

  const handleGetUser = async () => {
    const rep = await userApi.getUserById(id);
    setUser(rep);
    setInitialValues({ phone: rep.mobileNo, urlPreview: rep.urlAvatar !== '' ? BASE_URL_API+ rep.urlAvatar : null , ...rep });
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const handleFormSubmit = async (values: any) => {
    let dataUpdate = {}
    if(values.urlAvatar !== initialValues.urlAvatar){
      const base64 = await convertToBase64(values.urlAvatar[0]);
       dataUpdate = {
        username: values.email.split("@")[0],
        gender : values.gender,
        nickname: values.nickname,
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        mobileNo: values.phone,
        position: values.position,
        urlAvatar: base64,
      };
    }else{
       dataUpdate = {
        username: values.email.split("@")[0],
        gender : values.gender,
        nickname: values.nickname,
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        mobileNo: values.phone,
        position: values.position,
      };
    }


    if( await userApi.putUserPartial( initialValues.id,dataUpdate)){
      toast.success("Update success")
    }else{
      toast.warning("Update faild")
    };
  };
  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email!").required("Required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "phone number is not valid!")
      .required("Required"),
    position: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    nickname: yup.string(),
  });

  useEffect(() => {
    handleGetUser();
  },[]);

  if (!user) return <>User Not Found</>;

  return (
    <Box m="20px">
      <Header
        title="EDIT CONTACT"
        subtitle={`Edit a profile id ${initialValues.id}`}
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display={"flex"} flexDirection={"row"} sx={{ flexWrap:'wrap'}}>
              <Box flexGrow={2} display="flex"  flexDirection={"column"} justifyContent="start" alignItems="center"
              mb={3}>
                  <img
                      alt="profile-user"
                      width="200px"
                      height="200px"
                      src={ initialValues.urlPreview ?? `${BASE_URL_API}/images/brands/dafcLogo.jpg`}
                      className="cursor-pointer rounded-full object-cover h-100 w-100"
                      style={{height: '200px', width:'200px'}}
                  />
                  <input
                    type="file"
                    placeholder="Avatar"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("urlAvatar", e.target.files);
                      URL.revokeObjectURL(initialValues.urlPreview)
                      const urlPreview = URL.createObjectURL(e.target.files?.[0] ?? new Blob())
                      setInitialValues({ ...initialValues, urlPreview: urlPreview})
                    }}
                    name="urlAvatar"
                    className="mt-3 opacity-0 absolute cursor-cell"
                    style={{height:"200px"}}
                  />
                    <Box m={2} bgcolor={"white"} p={1}>
                      <ProfileQrCodeSmall url={`${window.location.origin}/profile/${initialValues.username}`} />
                    </Box>
                  </Box>
                <Box
                  // className={"md:w-screen"}
                  flexGrow={8}
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    select
                    label="Gender"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.gender}
                    name="gender"
                    defaultValue={values.gender}
                    error={!!touched.gender && !!errors.gender}
                    helperText={touched.gender && errors.gender}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {genderData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Nickname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nickname}
                    name="nickname"
                    error={!!touched.nickname && !!errors.nickname}
                    helperText={touched.nickname && errors.nickname}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={!!touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    error={!!touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    color="secondary"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Position"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.position}
                    name="position"
                    error={!!touched.position && !!errors.position}
                    helperText={touched.position && errors.position}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Box mt="20px">
                    <Button type="submit" color="secondary" variant="contained">
                      Update user
                    </Button>
                  </Box>
                </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UserEdit;
