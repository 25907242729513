import axiosClient from "./axiosClient";

const productApi = {
    export: async () => {
        const url = "/ecom/v1/products/export";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
    exportStock: async () => {
        const url = "/ecom/v1/products/exports/stock";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
    fileDownload: async () => {
        const url = "/ecom/v1/products/downloads";
        const response = await axiosClient.get(url);
        const dataResponse = response.data;
        return dataResponse;
    },
};

export default productApi;