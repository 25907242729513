export const convertToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const imageUrlToBase64 = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((onSuccess, onError) => {
      try {
          const reader = new FileReader();
          reader.onload = function () {
              onSuccess ( this.result )
          };
          reader.readAsDataURL(blob);
      } catch (e) {
          onError('');
      }
  });
};

